<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top"></div>
    </div>
    <el-row>
      <el-col :span="4">
        <el-menu
          :default-active="defaultActive"
          class="el-menu-vertical-demo"
        >
          <el-menu-item v-for="(i,index) in list" :key="index" :index="index+1+''" @click="handleMenu(i,index)">
            <i class="el-icon-menu"></i>
            <span slot="title">{{ i.name }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="20">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 1vh"
          height="77vh"
        >
          <el-table-column
            prop="deviceNumber"
            label="设备编号"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column
            prop="newTime"
            label="识别时间"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column
            prop="newCount"
            label="识别总数"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column
            prop="results"
            label="识别内容"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column
            prop="taggingImgUrlCompress"
            label="图片"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              <img :src="'http://insect.sennor.net:1881/openFile/'+scope.row.taggingImgUrlCompress" width="40" height="40" />
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <el-button @click="detail(scope.row)" type="success" size="small"
                >详情</el-button
              >
              <!--          <el-button @click="del(scope.row)" type="success" size="small">删除</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <div class="block pagingBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>

    <!--    详情-->
    <el-dialog
      title=""
      :visible.sync="messageBox"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            font-size: 2.22vh;
          "
        ></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :model="addForm" label-width="10vw">
          <el-form-item label="设备编号" prop="deviceNumber">
            <el-input v-model="addForm.deviceNumber"></el-input>
          </el-form-item>
          <el-form-item label="识别时间" prop="newTime">
            <el-input v-model="addForm.newTime"></el-input>
          </el-form-item>
          <el-form-item label="识别总数量" prop="newCount">
            <el-input v-model="addForm.newCount"></el-input>
          </el-form-item>
          <el-form-item label="识别内容" prop="results">
            <el-input type="textarea" v-model="addForm.results"></el-input>
          </el-form-item>
          <el-form-item label="图片" prop="taggingImgUrlCompress">
            <span class="imgBox"
              ><img :src="'http://insect.sennor.net:1881/openFile/'+addForm.taggingImgUrlCompress" @click="bigChange" alt=""
            /></span>
            <div v-show="big" class="big">
              <img :src="'http://insect.sennor.net:1881/openFile/'+addForm.taggingImgUrlCompress" @click="smallChange" alt="" />
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultActive:"1",
      value1: "",
      tableData: [],
      searchName: "",
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      formTitle: "",
      addForm: {},
      messageBox: false,
      showFlag: false,
      keyWord: "",
      boxPrompt: "",
      detailInfo: {},
      imgArr: [],
      img: "",
      imgSrcs: "",
      imgSrc: "",
      big: false,
      list:[]
    };
  },
  mounted() {
    this.getList2()
    // this.getList();
  },
  methods: {
    getList2() {
      this.$get("/insectManage/page", {
        page: 1,
        size: 500,
      }).then((res) => {
        if (res.data.state == "success") {
          this.list = res.data.datas;
          this.getList(this.list[0].deviceNumber)
        }
      });
    },
    // 重置
    update() {
      this.searchName = "";
      this.getList();
    },
    bigChange() {
      this.big = true;
    },
    smallChange() {
      this.big = false;
    },
    //请求列表数据
    getList(deviceNumber) {
      this.$get("/insectManage/pageHist", {
        page: this.page,
        size: this.size,
        deviceNumber: deviceNumber,
      }).then((res) => {
        if (res.data.state == "success") {
          let m = res.data.datas;
          m.forEach((v) => {
            try {
              this.imgSrcs = `http://insect.sennor.net:1881/openFile/${v.taggingImgUrlCompress}`;
              v.imgSrcs = `http://insect.sennor.net:1881/openFile/${v.taggingImgUrlCompress}`;
            } catch (err) {
              // this.imgList.imgSrcs =null;
            }
          });
          this.total = res.data.count;
          this.tableData = m;
        }
      });
    },
    //删除
    // del(item) {
    //   this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     this.$get("/remoteSelfHelp/deleteCropDiseasesDiscern", {
    //       id: item.id,
    //       kind: item.kind
    //     }).then((res) => {
    //       if (res.data.state == "success") {
    //         this.$message.success("删除成功");
    //         this.getList();
    //       } else {
    //         this.$message.info("删除失败");
    //       }
    //     });
    //   }).catch(() => {
    //     this.$message({
    //       type: "info",
    //       message: "已取消删除",
    //     });
    //   });
    // },
    // 详情
    detail(item) {
      this.big = false;
      this.showFlag = true;
      this.formTitle = "详情";
      this.messageBox = true;
      this.$nextTick(() => {
        this.addForm = JSON.parse(JSON.stringify(item));
      });
    },
    // 搜索
    search() {
      // this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    handleMenu(item,index){
      this.defaultActive = index+1+''
      this.getList(item.deviceNumber)
    }
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
/* #content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
} */

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button,
.el-button--info {
  margin-left: 10px !important;
}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}

.title /deep/ .el-form-item__content,
.el-date-editor,
.el-range-editor,
.el-input__inner,
.el-date-editor--datetimerange {
  padding: 0px 10px !important;
}

.title .upDate {
  position: absolute !important;
  right: 1vw !important;
}

.textA /deep/ .el-input__inner {
  height: 10vh;
}
.imgBox {
  width: 60%;
  display: inline-block;
}
.imgBox img {
  width: 100%;
}
.title /deep/.el-button {
  height: 40px;
}

.title /deep/ .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
}
.big {
  width: 130vh;
  height: 80vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.big img {
  width: 100%;
  height: 100%;
}
</style>
